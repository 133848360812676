import { extendTheme } from "@chakra-ui/react";

const zIndices = {
    zIndices: {
      hide: -1,
      auto: "auto",
      base: 0,
      docked: 10,
      dropdown: 1000,
      sticky: 1100,
      banner: 1200,
      overlay: 1300,
      modal: 1400,
      popover: 1500,
      skipLink: 1600,
      toast: 1700,
      tooltip: 1800,
    },
  };
  const theme = extendTheme({ 
    zIndices, 
    styles: {
            global: (props) => ({
            "html, body": {
                fontSize: "sm",
                color: props.colorMode === "dark" ? "white" : "gray.600",
                lineHeight: "tall",
            },
            a: {
                color: props.colorMode === "dark" ? "teal.300" : "teal.500",
            },
            }),
        },
    }
);

export {
    theme
};
