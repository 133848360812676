.boxShadow {
    box-shadow: 2px 2px 3px #ccc;
}

.zoomBox {
    position: absolute;
    z-index: 100;
    right: 10px;
    bottom: 10px;
    text-align: center;
    background: transparent;
    padding: 0px;
    color: #fff;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
}

.scaleVal {
    margin-top: 4px;
    height: 20px;
    background: var(--chakra-colors-teal-500);
    border-radius: 4px;
}